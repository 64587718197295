import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  addLicensingPartnerMapFailure,
  addLicensingPartnerMapInit,
  addLicensingPartnerMapSuccess,
  getLicensingPartnerMapInit,
  getLicensingPartnerMapSuccess,
  getLicensingPartnerMapFailure,
  updateActiveStatusInit,
  updateActiveStatusSuccess,
  updateActiveStatusFailure,
} from './licensingPartnersMapSlice';

const getLicensingPartnerMap = createAction('licensingPartnerMap/get');
const createLicensingPartnerMap = createAction('licensingPartnerMap/create');
const updateActiveStatusMap = createAction('licensingPartnerMap/updateActiveStatus');

function* createLicensingPartnerMapSaga({ payload: data }) {
  yield put(addLicensingPartnerMapInit());

  try {
    let response = yield callWrapperSaga(() =>
      http.post(apiEndpoints.createLicensingPartnerMapper, data),
    );

    yield put(addLicensingPartnerMapSuccess(response.data));

    Notify.success(response.data.message);
  } catch (err) {
    yield put(addLicensingPartnerMapFailure(err));
    Notify.error(err.response.data.message);
  }
}

function* getLicensingPartnerMapSaga({ payload: partnerId }) {
  yield put(getLicensingPartnerMapInit());

  try {
    let endpoint = buildApiRoute(apiEndpoints.getLicensingPartnerMap, partnerId);
    let response = yield callWrapperSaga(() => http.get(endpoint));

    yield put(getLicensingPartnerMapSuccess(response.data));
  } catch (error) {
    yield put(getLicensingPartnerMapFailure(error));
  }
}

function* updateActiveStatusSaga({ payload: data }) {
  yield put(updateActiveStatusInit());
  try {
    let endpoint = buildApiRoute(apiEndpoints.updatelicensePartnerMapStatus, data.partner_map_id);

    let response = yield callWrapperSaga(() => http.patch(endpoint));
    yield put(updateActiveStatusSuccess(response.data));

    Notify.success(response.data.message);
  } catch (error) {
    yield put(updateActiveStatusFailure(error));
    Notify.error(error.response.data.message);
  }
}

function* licensingPartnerMapSaga() {
  yield takeLatest(getLicensingPartnerMap, getLicensingPartnerMapSaga);
  yield takeLatest(createLicensingPartnerMap, createLicensingPartnerMapSaga);
  yield takeLatest(updateActiveStatusMap, updateActiveStatusSaga);
}

export default licensingPartnerMapSaga;

export { getLicensingPartnerMap, createLicensingPartnerMap, updateActiveStatusMap };
