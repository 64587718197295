import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import PageContent from 'layouts/PageContent';
import LicensingPartnerForm from 'components/LicensingPartner/LicensingPartnerForm';
import LicensingPartnerFormSkeleton from 'components/LicensingPartner/LicensingPartnerFormSkeleton';
import LicensingPartnerProgressIndicatorModal from 'components/LicensingPartner/LicensingPartnerProgressIndicatorModal';

import acl from 'app/config/acl';
import Error from 'core/errors/Error';
import {
  updateLicensingPartner,
  getLicensingPartner,
} from 'app/features/licensing-partners/licensingPartnerSaga';

function EditLicensingPartner() {
  const dispatch = useDispatch();
  const params = useParams();

  const { isError, errorMessage, isLoading, errorCode } = useSelector(
    (state) => state.licensingPartners.update,
  );

  const { data: licensingPartnerData } = useSelector((state) => state.licensingPartners.show);

  useEffect(() => {
    if (params.licensingPartnerId) {
      dispatch(getLicensingPartner(params.licensingPartnerId));
    }
  }, [params.licensingPartnerId, dispatch]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(updateLicensingPartner(params.licensingPartnerId))}
      />
    );

  return (
    <PageContent title="Edit Licensing partner">
      {isLoading ? (
        <LicensingPartnerFormSkeleton />
      ) : (
        <>
          <LicensingPartnerForm isAddMode={false} licensingPartner={licensingPartnerData} />

            <LicensingPartnerProgressIndicatorModal isAddMode={false} licensingPartner={licensingPartnerData} />
        </>
      )}
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditLicensingPartner);
