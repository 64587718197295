import PropTypes from 'prop-types';
import FormCheckbox from 'core/forms/FormCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from 'core/table/TableContainer';
import React, { useMemo, useEffect, useState } from 'react';
import TanstackReactTable from 'core/table/TanstackReactTable';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import Auth from 'utils/Auth';
import DateUtils from 'utils/DateUtils';
import constants from 'app/config/constants';
import TableSwitch from 'core/table/TableSwitch';
import VerifiedBadge from 'core/badges/VerifiedBadge';

import { useConfirm } from 'core/mui-confirm';
import useCountries from 'hooks/useCountries';
import LicensePartnerMapperPagination from './LicensePartnerMapperPagination';
import { updateActiveStatusReset } from 'app/features/licensing-partners-map/licensingPartnersMapSlice';
import { getLicensingPartnerMap, updateActiveStatusMap } from 'app/features/licensing-partners-map/licensingPartnersMapSaga';

export default function LicensePartnerMapper({ partnerId }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { getCountryNameByIso3Code } = useCountries();

  const { params, isError, errorCode, isLoading, data } = useSelector(
    (state) => state.licensingPartnersMap.index,
  );

  const { isSuccess: isUpdateSuccess } = useSelector(state => state.licensingPartnersMap.update)

  const handleChangeActiveStatus = (data) => {
    confirm({
      description: 'Are you sure you want to change the active status?',
      confirmationText: 'Yes',
      rejectionText: 'Cancel',
    }).then(() => {
      dispatch(updateActiveStatusMap({
        partner_map_id: data?.row?.original?.id
      }))
    })
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        cell: (info) => info.row.index + 1,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partnerName,
            },
          ]
        : []),
      {
        header: 'Licensing Partner Name',
        accessorFn: (row) => row.licenseName,
      },
      {
        header: 'Country',
        accessorFn: (row) => row.country,
        cell: (info) => getCountryNameByIso3Code(info.getValue()),
      },
      {
        header: 'Is Selected ?',
        accessorFn: (row) => row.is_selected,
        cell: (info) => <VerifiedBadge isVerified={!!info.getValue()} />,
      },
      {
        header: 'Is Deleted ?',
        accessorFn: (row) => row.is_deleted,
        cell: (info) => <VerifiedBadge isVerified={!!info.getValue()} />,
      },
      {
        header: 'Is Active?',
        accessorFn: (row) => row.is_active,
        cell: (info) => (
          <TableSwitch value={info.getValue()} data={info.row.original} handleStatus={() => handleChangeActiveStatus(info)} />
        )
      },
      {
        header: 'Created Date',
        accessorFn: (row) => (row.created_ts ? DateUtils.isoToString(row.created_ts) : '-'),
      },
    ],
    [JSON.stringify(data)],
  );

  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((c) => c?.isVisible === false)
      .map((c) => c.id)
      .reduce(
        (a, k) => ({
          ...a,
          [k]: false,
        }),
        {},
      ),
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getLicensingPartnerMap(partnerId), {
      ...params,
    });
    if (isUpdateSuccess) {
      dispatch(updateActiveStatusReset())
    }
  }, [partnerId, JSON.stringify(params), isUpdateSuccess]);

  return (
    <TableContainer>
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <LicensePartnerMapperPagination />
    </TableContainer>
  );
}

LicensePartnerMapper.propTypes = {
  partnerId: PropTypes.string,
};
